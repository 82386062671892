
@import 'alabaster';

//                            .8888b oo
//                            88   "
// .d8888b. .d8888b. 88d888b. 88aaa  dP .d8888b.
// 88'  `"" 88'  `88 88'  `88 88     88 88'  `88
// 88.  ... 88.  .88 88    88 88     88 88.  .88
// `88888P' `88888P' dP    dP dP     dP `8888P88
//                                           .88
//                                       d8888P

$font-monospace: 'Source Code Pro', 'Consolas', 'Menlo', 'Deja Vu Sans Mono', 'Bitstream Vera Sans Mono', monospace;
$font-title: 'Garamond', 'Georgia', serif;

$bg-tag: #ecf0f3;
// $bg-script: #fbfbfc;
$bg-script: #f5f5fa;
// $bg-code: #f5f5fa;
$bg-code: $bg-script;
$text-status: #888;
$text-light: #666;
$text-tag: #333;
$text-link: #3782be;
$text-body: #3e4349;
$text-lighter: #444;
$text-light-on-dark: #aaa;

$text-warn1: $text-body;
$text-warn2: green;
$text-warn3: blue;
$text-warn4: orange;
$text-warn5: red;

$font-size: 17px;
$font-size1: 240%;
$font-size2: 180%;
$font-size3: 150%;
$font-size4: 130%;
$font-size5: 100%;
$font-size6: 100%;

//                                                       dP
//                                                       88
// .d8888b. .d8888b. 88d888b. .d8888b. 88d888b. .d8888b. 88
// 88'  `88 88ooood8 88'  `88 88ooood8 88'  `88 88'  `88 88
// 88.  .88 88.  ... 88    88 88.  ... 88       88.  .88 88
// `8888P88 `88888P' dP    dP `88888P' dP       `88888P8 dP
//      .88
//  d8888P

.clear { clear: both; }

a,
a:visited {
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
header {
  a {
    border: 0;
    color: $text-link;
    text-decoration: none;

    &:hover {
      border: 0;
      text-decoration: underline;
    }

    code {
      color: $text-link;
      font-weight: normal;
      &:hover { background: none; }
    }
  }

  code {
    font-family: $font-monospace;
    font-size: 75%;
  }
}

div.body>h1 {
  margin-bottom: .5em;
}

.footer { padding-bottom: 3em; }

.toplink {
  // float: right;
  margin: 1em 0 0.5em;
  text-align: right;
  // width: 100%;
}

.jumpnav { float: right; }


// dP   dP  a8888a  dP   dP
// 88   88 d8' ..8b 88   88
// 88aaa88 88 .P 88 88aaa88
//      88 88 d' 88      88
//      88 Y8'' .8P      88
//      dP  Y8888P       dP

.notfound {
  display: inline-block;
  text-align: center;

  figcaption {
    font-size: 140%;
    padding: .5em;
  }
}

//          oo       dP          dP
//                   88          88
// .d8888b. dP .d888b88 .d8888b. 88d888b. .d8888b. 88d888b.
// Y8ooooo. 88 88'  `88 88ooood8 88'  `88 88'  `88 88'  `88
//       88 88 88.  .88 88.  ... 88.  .88 88.  .88 88
// `88888P' dP `88888P8 `88888P' 88Y8888' `88888P8 dP

// Override sphinx sidebar styles
div.sphinxsidebar {

  p.logo,
  h1.logo {
    text-align: right;
  }

  p.logo {
    img {
      max-width: 128px;
      max-height: 128px;
    }
  }

  a,
  a:hover { border: 0; }

  a:hover { text-decoration: underline; }

  ul.social,
  div.about { margin: 1.5em 0 0; }

  h2 { margin: 10px 0; }

  .related {
    // text-align: left;

    li+li {
      margin-top: 8px;
    }

    a {
      font-weight: bold;
    }

    .direction {
      // background: $bg-tag;
      display: inline-block;
      line-height: 1em;
      margin: 0;
      padding: 2px 3px;
    }
  }
}

// div.sphinxsidebarwrapper h1.logo,
.sphinxsidebar {
  text-align: right;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-lighter;
  }

  .about,
  .about a { color: $text-status; }
}


//   dP
//   88
// d8888P .d8888b. .d8888b. .d8888b.
//   88   88'  `88 88'  `88 Y8ooooo.
//   88   88.  .88 88.  .88       88
//   dP   `88888P8 `8888P88 `88888P'
//                      .88
//                  d8888P

.tag {
  background: $bg-tag;
  border: 0;
  color: $text-tag;
  display: inline-block;
  font-family: 'Consolas', 'Menlo', 'Deja Vu Sans Mono', 'Bitstream Vera Sans Mono', monospace;
  font-weight: bold;
  padding: 0 4px;
  text-decoration: none;

  a,
  a:hover {
    color: $text-tag;
  }
}

.sphinxsidebar .tags,
.tags {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 0 .375em .375em;
  }

  a,
  a:hover {
    border: 0;
    display: inline-block;
  }
}


h1 .tag {
  font-size: 65%;
}


//                              dP
//                              88
// .d8888b. dP    dP 88d888b. d8888P .d8888b. dP.  .dP
// Y8ooooo. 88    88 88'  `88   88   88'  `88  `8bd8'
//       88 88.  .88 88    88   88   88.  .88  .d88b.
// `88888P' `8888P88 dP    dP   dP   `88888P8 dP'  `dP
//               .88
//           d8888P

pre,
code,
kbd {
  // background-color: inherit;
  // font-weight: 600;
  // display: inline-block;
  background-color: $bg-code;
  padding: 2px 4px;
}

kbd {
  border: 1px solid $bg-tag;
  border-radius: 4px;
  // display: inline-block;
  // padding: 2px 4px;
}

//                         dP
//                         88
// .d8888b. .d8888b. .d888b88 .d8888b.
// 88'  `"" 88'  `88 88'  `88 88ooood8
// 88.  ... 88.  .88 88.  .88 88.  ...
// `88888P' `88888P' `88888P8 `88888P'


pre {
  padding: 0 !important;
  padding-left: 1em !important;

  code {
    display: block;
    padding: 1em 0.5em;

    &.hljs {
      background-color: $bg-code;
      padding: 1em 0.5em;
    }
  }
}

div.code {
  border: 1px solid $bg-tag;

  header {
    align-items: stretch;
    border-bottom: 1px solid $bg-tag;
    display: flex;
    padding: 0.5em;

    .filename {
      color: $text-light;
      display: inline-block;
      font-family: $font-monospace;
      font-size: 0.9em;
      margin-right: auto;
    }

    .rawlink {
      font-size: 1em;
    }
  }

  pre {
    margin: 0;
    padding: 0 !important;
  }

  /*
  code {
    padding: 1em;
  }
  */

  pre,
  code,
  .hljs {
    background-color: $bg-script;
  }

  /*
  pre {
    background-color: $bg-code;
    color: inherit;
    margin: 0;
    padding: 0;
    // padding: 7px 16px;

    code {
      background-color: inherit;
      padding: 0.5em;
      // color: inherit;
    }
  }
  */
}

div.chroma,
pre.chroma {
  padding: 7px 16px;

  code {
    background-color: inherit;
    color: inherit;
    // font-weight: normal;
  }
}

.chroma {

  pre {
    // line numbers
    &.chroma {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
      padding-right: 16px;

      .lnt {
        color: #999;
        margin: 0 8px 0 0;
        padding: 0;
      }
    }
  }
}


// 88d888b. .d8888b. .d8888b. .d8888b. .d8888b.
// 88'  `88 88'  `88 88'  `88 88ooood8 Y8ooooo.
// 88.  .88 88.  .88 88.  .88 88.  ...       88
// 88Y888P' `88888P8 `8888P88 `88888P' `88888P'
// 88                     .88
// dP                 d8888P


.pages {
  list-style: none;
  margin: 0;
}


.meta {
  display: table;
  font-size: 90%;
  padding-bottom: .5em;
  width: 100%;

  .left,
  .right {
    display: table-cell;
  }

  .left {
    padding-right: 1rem;
    white-space: nowrap;
  }

  .tags {
    text-align: right;
    font-size: 90%;
  }
}


.summary {
  clear: both;
}

.read-more-link {
  font-size: 90%;
  line-height: 1em;
  text-align: right;
}

.status {
  color: $text-status;
  font-family: $font-monospace;
  font-size: 65%;
}

aside {

  &.related {
    margin-top: 1em;

    h2 {
      font-size: 150%;
      margin-top: 0;
    }
  }
}


//                            oo                     dP
//                                                   88
// 88d888b. 88d888b. .d8888b. dP .d8888b. .d8888b. d8888P
// 88'  `88 88'  `88 88'  `88 88 88ooood8 88'  `""   88
// 88.  .88 88       88.  .88 88 88.  ... 88.  ...   88
// 88Y888P' dP       `88888P' 88 `88888P' `88888P'   dP
// 88                         88
// dP

.projects {
  list-style: none;
  margin: 0;

  .project {
    // background: url('../images/github-24.png') left 2px no-repeat;
    // background: url('../images/icons.svg#github') left 2px no-repeat;
    padding: 0 0 1em;
  }

  header {
    font-size: $font-size3;
    font-weight: normal;
  }

  // No. of stars in project title
  .status {
    a {
      color: $text-status;
      font-size: .7em;
    }
  }

  .icon {
    display: block;
    float: left;
    padding-top: 2px;
    width: 30px;

    svg {
      height: 24px;
      fill: #333333;
      width: 24px;
    }
  }

  .details { display: inline-block; }

  .meta {
    // No. of issues in status list
    .status { font-size: 80%; }
  }
}


//                                       dP
//                                       88
// .d8888b. dP   .dP .d8888b. 88d888b. d8888P .d8888b.
// 88ooood8 88   d8' 88ooood8 88'  `88   88   Y8ooooo.
// 88.  ... 88 .88'  88.  ... 88    88   88         88
// `88888P' 8888P'   `88888P' dP    dP   dP   `88888P'

@mixin type-icon {
  .icon {
    display: table-cell;
    width: 24px;

    svg {
      display: block;
      position: relative;
      top: 3px;
    }
  }
}

li {
  &.post,
  &.link,
  &.event {
    // border-bottom: 1px dashed purple;
    // margin: 1em 0;
    margin-top: 30px;

    &>* {
      margin-left: 30px;
    }

    h2 {
      font-size: $font-size4;
      line-height: $font-size4 + 4;
      margin-top: 0;
    }

    .icon {
      float: left;
      margin: 0;
      width: 28px;

      svg {
        display: block;
        fill: $text-body;
        height: 24px;
        position: relative;
        top: 2px;
        width: 24px;
      }
    }
  }
}

.events {
  list-style: none;
  margin-left: 0;

  .event {
    padding-bottom: 1em;
    // &:last-child { padding-bottom: 0; }

    .details {
      display: table-cell;
    }
  }

  .meta {
    color: $text-status;
    font-size: .8em;
  }

  .issue { font-style: italic; }
  .what { font-weight: bold; }
}


// dP oo          dP
// 88             88
// 88 dP 88d888b. 88  .dP  .d8888b.
// 88 88 88'  `88 88888"   Y8ooooo.
// 88 88 88    88 88  `8b.       88
// dP dP dP    dP dP   `YP `88888P'

.links {
  list-style: none;
  margin-left: 0;

  >li { padding-bottom: .5em; }

  .summary {
    color: $text-light;
    font-size: .9em;
    margin-top: .5em;
  }
}

// .8888b                                                         dP
// 88   "                                                         88
// 88aaa  .d8888b. 88d888b. .d8888b. .d8888b. .d8888b. .d8888b. d8888P
// 88     88'  `88 88'  `88 88ooood8 88'  `"" 88'  `88 Y8ooooo.   88
// 88     88.  .88 88       88.  ... 88.  ... 88.  .88       88   88
// dP     `88888P' dP       `88888P' `88888P' `88888P8 `88888P'   dP


.forecasts {
  list-style: none;
}

.forecast {

  // .warn1 {}
  .warn2 { color: $text-warn2; }
  .warn3 { color: $text-warn3; }
  .warn4 { color: $text-warn4; }
  .warn5 { color: $text-warn5; }

  header {
    font-family: $font-title;
    font-size: 1.4em;
    font-weight: bold;
    margin: .25em 0;

    .icon { font-size: 2em; }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;

    td {
      margin: 0;
      padding: .125em 0;

      .icon {
        font-size: 1.6em;
        padding: 0 .25em;
        text-align: center;
      }
    }
  }

  >div { margin: .2em 0; }
  .temp { font-size: 1.75em; }
  .wind { vertical-align: middle; }
  +.forecast { margin-top: 1.5em; }
}


//                            oo          dP
//                                        88
// .d8888b. .d8888b. .d8888b. dP .d8888b. 88
// Y8ooooo. 88'  `88 88'  `"" 88 88'  `88 88
//       88 88.  .88 88.  ... 88 88.  .88 88
// `88888P' `88888P' `88888P' dP `88888P8 dP

// Social icons with rollover
.social {

  li {
    display: inline-block;
    margin: 0 0 0 .25em;
  }

  svg {
    fill: $text-lighter;
  }

  a {
    border: 0;
    display: block;
    margin: 0;
    max-height: 32px;
    padding: 0;
    &:hover { border: 0; }

    &.twitter:hover,
    &.rss:hover,
    &.github:hover {
      svg {
        fill: $text-link;
      }
    }
  }
}


//          oo   dP
//               88
// .d8888b. dP d8888P
// 88'  `88 88   88
// 88.  .88 88   88
// `8888P88 dP   dP
//      .88
//  d8888P

.gitinfo {
  color: $text-status;
  float: right;

  .content { display: none; }
  // .hash { display: inline-block; }
  .header,
  .meta { text-align: right; }

  code,
  .info { color: $text-status; }

  th {
    text-align: right;
    vertical-align: text-top;
  }
}

//       dP                              dP                         dP
//       88                              88                         88
// .d888b88 .d8888b. dP  dP  dP 88d888b. 88 .d8888b. .d8888b. .d888b88 .d8888b.
// 88'  `88 88'  `88 88  88  88 88'  `88 88 88'  `88 88'  `88 88'  `88 Y8ooooo.
// 88.  .88 88.  .88 88.88b.88' 88    88 88 88.  .88 88.  .88 88.  .88       88
// `88888P8 `88888P' 8888P Y8P  dP    dP dP `88888P' `88888P8 `88888P8 `88888P'

div.downloads {
  font-weight: bold;

  h2 {
    font-size: 150%;
  }

  ul {
    list-style: none;

    li {
      display: table-row;

      .download-icon {
        // border: 1px dotted green;
        height: 32px;
        width: 32px;
      }

      &>* {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

//                            oo                     dP   oo
//                                                   88
// 88d888b. .d8888b. .d8888b. dP 88d888b. .d8888b. d8888P dP .d8888b. 88d888b.
// 88'  `88 88'  `88 88'  `88 88 88'  `88 88'  `88   88   88 88'  `88 88'  `88
// 88.  .88 88.  .88 88.  .88 88 88    88 88.  .88   88   88 88.  .88 88    88
// 88Y888P' `88888P8 `8888P88 dP dP    dP `88888P8   dP   dP `88888P' dP    dP
// 88                     .88
// dP                 d8888P

.pager,
.pagination {
  display: block;
  margin: 1em 0;
  text-align: center;
  width: 100%;

  li {
    display: inline-block;
    list-style: none;

    &:first-child,
    &:last-child {
      margin: 0 1em;
      .page { padding: 0; }
    }

    .page {
      display: block;
      padding: 0 .5em;
    }

    &.disabled,
    &.current {
      color: $text-status;
    }

    // &.previous { float: left; }
    // &.next { float: right; }
  }
}

//                                                                 oo
//
//  88d888b. .d8888b. .d8888b. 88d888b. .d8888b. 88d888b. .d8888b. dP dP   .dP .d8888b.
//  88'  `88 88ooood8 Y8ooooo. 88'  `88 88'  `88 88'  `88 Y8ooooo. 88 88   d8' 88ooood8
//  88       88.  ...       88 88.  .88 88.  .88 88    88       88 88 88 .88'  88.  ...
//  dP       `88888P' `88888P' 88Y888P' `88888P' dP    dP `88888P' dP 8888P'   `88888P'
//                             88
//                             dP

.mobile-menu { display: none; }

@media screen and (max-width: 875px) {
  .mobile-menu {
    display: block;
    margin-left: 1em;
  }

  div.sphinxsidebar {
    text-align: center;

    .tag {
      background: $text-light-on-dark;
      color: $text-tag;
    }

    h1.logo { text-align: center; }

    h2 { color: $text-light-on-dark; }
  }

  .meta {
    display: block;
    padding-bottom: 0;

    .left,
    .right {
      display: block;
    }

    .left {
      padding-bottom: .5em;
    }

    .tags {
      text-align: left;
    }
  }

  .social {
    li {
      a {
        &.twitter { background-position: 0 -64px; }
        &.rss { background-position: -32px -64px; }
        &.github { background-position: -64px -64px; }
      }
    }
  }
}
//  vim: set ft=scss ts=2 sw=2 tw=80 et :
